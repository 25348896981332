.daily-deals {
	.row {
		.daily-post:nth-child(1) {
			display: block!important;
		}
		.featured-daily-post {
			border-radius: 20px;
		}
		 .daily-post {
			 border-radius: 20px;
			 display: none!important;
		 }
		 .border-large {
			 border-radius: 20px;
		 }
		.single-deal {
			transition:all 350ms ease-in-out;
			margin-bottom:15px;
			&:last-of-type {
				margin-bottom:0;
			}
			.single-deal-button {
				opacity:0;
			}
			&:hover {
				box-shadow:0;
				color:white!important;
				.single-deal-button {
					opacity:1;
				}
				.deal-fine-print {
					color:white;
				}
			}
			p {
				margin-bottom:0;
			}
		}
		a.primary-btn:hover {
				background: #fff;
				border-color: #fff;
		}
	}
}

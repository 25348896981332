#ageGateModal {
    background: linear-gradient(180deg, #ee4405 0%, #f14fab 65%, #f25e88 80%, #f25e88 100%);
    overflow: hidden;
  .modal-dialog {
    max-width: 950px;
    text-align: center;
    .modal-content {
      background: transparent;
      box-shadow: none;
      border: none;
      position: relative;
      z-index: 100;
      .modal-title {
        font-size: 90px;
        @include media-breakpoint-down(md) {
          font-size: 52px;
          line-height: 1.25;
        }
      }
    }
    .modal-footer {
      .btn-modal-yes, .btn-modal-no {
        width: 285px;
        height: 285px;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        background: rgba(255,255,255,0.35);
        @include media-breakpoint-down(md) {
            display: block;
            width: 155px;
            height: 155px;
        }
        .button-title {
          font-size: 64px;
          @include media-breakpoint-down(md) {
              font-size: 48px;
          }
        }
        strong {
          font-size: 24px;
          line-height: 1;
        }
        .modal-btn {
          border: 1px solid #fff;
          padding: 8px 10px;
          background: rgba(255,255,255,0.1);
          max-width: 130px;
          margin: 0 auto;
          @include media-breakpoint-down(md) {
              max-width: 90px;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
          }
          &:hover {
            background: $brand-primary;
            border-color: $brand-primary;
            color: $brand-secondary;
          }
        }
      }
    }
  }
  .bg-bubbles {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;
    .bg-bubbles-one, .bg-bubbles-two, .bg-bubbles-three {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      background-size: cover!important;
    }
  }
}

.top-popup {
  z-index:1060!important;
  .top-popup-image {
    .btn-close {
      color:white;
      background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
      padding:0;
      position:absolute;
      top:3px;
      right:-35px;
      opacity:1;
      z-index:100;
    }
  }
  .modal-body {
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
  }
}

.shop-by-location-modal {
  .btn-close {
    color:white;
    background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    opacity:1;
    position:absolute;
    top: 18px;
    right: 20px;
    z-index: 100;
    opacity: 0.85;
  }
  .modal-dialog {
    .modal-content {
      border-radius: 23px;
      border: 1px solid #fff;
      background-color:rgba(255,255,255,0.25);
      -webkit-backdrop-filter: saturate(180%) blur(16px);
      backdrop-filter: saturate(180%) blur(16px);
      padding-bottom: 1.25rem;
      p {
       color: #191919;
      }
      .modal-body {
        .splide__slider {
          .rpb-slider-arrows {
            .splide__arrow--prev {
              left:0;
            }
            .splide__arrow--next {
              right:0;
            }
          }
          .splide__track {
            margin-left:3rem;
            margin-right:3rem;
          }
        }
        .location-popup-image {
          background-size:cover;
          background-position:center;
          min-height:50px;
          border-radius: 14px 14px 0 0;
          @include media-breakpoint-up(lg) {
            min-height:150px;
          }
        }
        .location-content {
          border-radius: 0 0 14px 14px;
        }
        .splide__pagination {
          display:none!important;
        }
      }
    }
  }
}

// Default Slider Styles
.range-splider {
  .splide__slider {
    .rpb-slider-arrows {
      .splide__arrow {
        background: rgba(255, 255, 255, 0.1);
        opacity:1;
        box-shadow: none!important;
        border:1px solid #fff;
        svg {
          color:rgba(0,0,0,0.25);
        }
      }
      .splide__arrow--prev {
        svg {
          transform:none;
        }
      }
      .splide__arrow--next {
        svg {
          transform:none;
        }
      }
    }
  }
  .splide__pagination {
    @media-breakpoint-down(md) {
      display:none;
    }
    .splide__pagination__page.is-active {
      background:$brand-primary;
    }
  }
  &.blurred-slides {
    .splide__slide {
      .featured-image {
        border-radius: 23px;
      }
      &:before {
        content:'';
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background:rgba(0,0,0,0.5);
        z-index: 1;
        backdrop-filter: saturate(180%) blur(2px);
        transition:all ease-in-out 350ms;
        opacity:1;
      }
      &.is-active {
        &:before {
          content:'';
          opacity:0;
        }
      }
    }
  }
}

#rpb-blog-slider {
  .splide__slider {
    .splide__track {
      padding-bottom: 30px;
      .splide__slide {
        border-radius: 23px;
        position: relative;
        overflow: visible;
        &:before {
          content:'';
          background: #fff;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          border-radius: 23px;
        }
        .featured-image {
          img {
            border-radius: 23px;
          }
        }
        .secondary-btn {
          position: absolute;
          bottom: -24px;
          left: 0;
          right: 0;
          margin: 0 auto;
          max-width: 182px;
        }
      }
    }
  }
}

// Thumbnail Slider
.range-splider-thumbnails {
  .splide__track {
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    background:white;
    .splide__list {
      .splide__slide {
        opacity:0.3;
        &.is-active {
          border:0!important;
          opacity:1;
        }
      }
    }
  }
}

// Logo Banner Slider
.rpb-logo-slider {
  .splide__slider {
    .rpb-slider-arrows {
      .splide__arrow--prev {
        left:0;
      }
      .splide__arrow--next {
        right:0;
      }
    }
    .splide__track {
      margin-left:3rem;
      margin-right:3rem;
    }
  }
}

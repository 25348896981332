// Theme Colors/Branding
$brand-primary: #E1F651;
$brand-primary-rgb: 20,89,186;
$brand-primary-offset: #1459BA;
$brand-secondary: #285066;
$dark: #222;
$light: #f5f5f5;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: #ee4405;
$accent-2: #C6DC61;
$body-font: #222;
$error: #d81e00;
$green: #91d09c;

.footer-outer-wrap {
	.joy-wave {
		position: absolute;
		transform: translateY(-99%);
		width: 100%;
		height: auto;
	}

	.joyology-large {
		max-width: 450px;
		transform: translateY(-100%);
		width: 100%;
		@include media-breakpoint-down(xl) {
			transform: translateY(-25%);
		}
		@include media-breakpoint-down(lg) {
			display: block;
			max-width: 325px;
			width: 96%;
			margin: 1rem auto;
		}
	}

 ul#menu-footer-first-menu {
	 list-style: none;
	 text-transform: uppercase;
	 padding-left: 0;
	 li {
		 line-height: 2.25;
		 a {
			 font-size: 22px;
			 color: $brand-primary;
			 opacity: 1;
			 letter-spacing: 2px;
			 &:hover {
				 opacity: 0.75;
			 }
		 }
	 }
 }

 .newsletter-form-wrap {
	 padding: 10px 10px 10px 15px;
	 border-radius: 30px;
	 background: #fff;
	 width: 80%;
	 @include media-breakpoint-down(lg) {
		 display: block;
		 margin: 2rem auto 0;
	 }
	 input {
		 border: none;
		 @include media-breakpoint-down(md) {
			 text-align: center;
		 }
	 }
	 #field_2_2 {
		 text-align: right;
		 @include media-breakpoint-down(md) {
			 text-align: center;
		 }
		 button {
			 border: none;
			 background: $brand-primary;
			 color: $brand-secondary;
			 font-weight: bold;
			 text-transform: uppercase;
			 border-radius: 30px!important;
			 padding: 7px 32px !important;
		 }
	 }
 }

	.footer-seo-headings {
		display: none;
		h1, h2, h3 {
			background: transparent;
			color: #fff;
		}
		h3 {
			.tag {
				color: #fff;
				background: transparent;
			}
		}
			@media screen and (min-width: 2560px) {
					display: block;
			}
	}

	.site-footer {
		img {
			width:auto;
			max-height:80px;
		}
		.footer-main {
			i,svg {
				min-width:30px;
				color:white;
			}
		}
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			li {
				padding-bottom:7.5px;
				&:last-of-type {
					padding-bottom:0;
				}
				a {
					color: white;
					text-decoration: none;
					font-weight: normal;
				}
			}
		}
		.gform_wrapper {
			.gform_description {
				margin-bottom:1rem;
				display:block;
			}
			.gfield_required_text {
				color:white;
			}
		}
		.gform_wrapper.gravity-theme .gform_footer button, .gform_wrapper.gravity-theme .gform_footer input, .gform_wrapper.gravity-theme .gform_page_footer button, .gform_wrapper.gravity-theme .gform_page_footer input {
			background:white;
			border:4px solid white;
			color:$brand-primary;
			&:hover {
				background:lighten($brand-primary,15%);
				border:4px solid lighten($brand-primary,15%);
				color:white;
			}
		}
	}

}

.footer-social {
	br {
		display: none;
	}
	svg:hover, a:hover {
		opacity: 0.75;
		color: $brand-primary;
	}
}

#copyright {
	a {
		opacity: 1;
		&:hover {
			color: $brand-primary;
			opacity: 0.75;
		}
	}
}

.notunder1500 {
	.btn-xs {
		border-radius: 0!important;
	}
}

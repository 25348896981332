// Backgrounds
.background-image {
	background-color: $white;
	background-position: 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.background-white {
	background-color: #fff;
}
.background-color-trans-white {
	background-color: rgba(255, 255, 255, 0.75);
}
.background-black {
	background-color: #000;
	&.background-gradient {
		background: #000;
		background: linear-gradient(145deg, #000 0%, lighten(#000,15%) 100%);
	}
}
.background-trans-black {
	background-color: rgba(0, 0, 0, 0.75);
}
.background-primary {
	background-color: $brand-primary;
	&.background-gradient {
		background: $brand-primary;
		background: linear-gradient(145deg, $brand-primary 0%, lighten($brand-primary,15%) 100%);
	}
	&.reference-primary {
		&:before {
			content:'#{$brand-primary} \A .background-primary \A .color-primary \A .color-primary-important';
		}
	}
}

.background-secondary {
	background-color: $brand-secondary;
	&.background-gradient {
		background: $brand-secondary;
		background: linear-gradient(145deg, $brand-secondary 0%, lighten($brand-secondary,15%) 100%);
	}
	&.reference-secondary {
		&:before {
			content:'#{$brand-secondary} \A .background-secondary \A .color-secondary \A .color-secondary-important';
		}
	}
}
.background-dark {
	background-color: $dark;
	&.background-gradient {
		background: $dark;
		background: linear-gradient(145deg, $dark 0%, lighten($dark,15%) 100%);
	}
	&.reference-dark {
		&:before {
			content:'#{$dark} \A .background-dark \A .color-dark \A .color-dark-important';
		}
	}
}
.background-gray {
	background-color: $gray;
	&.background-gradient {
		background: $gray;
		background: linear-gradient(145deg, $gray 0%, lighten($gray,15%) 100%);
	}
	&.reference-gray {
		&:before {
			content:'#{$gray} \A .background-gray \A .color-gray \A .color-gray-important';
		}
	}
}
.background-accent {
	background-color: $accent;
	&.background-gradient {
		background: $accent;
		background: linear-gradient(145deg, $accent 0%, lighten($accent,15%) 100%);
	}
	&.reference-accent {
		&:before {
			content:'#{$accent} \A .background-accent \A .color-accent \A .color-accent-important';
		}
	}
}
.background-light {
	background-color: $light;
	&.background-gradient {
		background: $light;
		background: linear-gradient(145deg, $light 0%, lighten($light,15%) 100%);
	}
	&.reference-light {
		&:before {
			content:'#{$light} \A .background-light \A .color-light \A .color-light-important';
		}
	}
}

.background-error {
	background-color: $error;
	&.background-gradient {
		background: $error;
		background: linear-gradient(145deg, $error 0%, lighten($error,15%) 100%);
	}
	&.reference-error {
		&:before {
			content:'#{$error} \A .background-error \A .color-error \A .color-error-important';
		}
	}
}

.background-green {
	background-color: $green;
}

.background-deals {
	background:$brand-primary;
	background: linear-gradient(90deg, rgba(200,55,8,1) 0%, $brand-primary 41%, lighten($brand-primary,15%) 100%);
}

.background-transparent {
	background-color:transparent;
}

// important overrides
.background-image-important {
	background-color: $white!important;
	background-position: 50%!important;
	background-size: cover!important;
	background-repeat: no-repeat!important;
}

.background-white-important {
	background-color: #fff!important;
}
.background-color-trans-white-important {
	background-color: rgba(255, 255, 255, 0.75)!important;
}
.background-black-important {
	background-color: #000!important;
	&.background-gradient {
		background: #000!important;
		background: linear-gradient(145deg, #000 0%, lighten(#000,15%) 100%)!important;
	}
}
.background-trans-black-important {
	background-color: rgba(0, 0, 0, 0.75)!important;
}
.background-primary-important {
	background-color: $brand-primary!important;
	&.background-gradient {
		background: $brand-primary!important;
		background: linear-gradient(145deg, $brand-primary 0%, lighten($brand-primary,15%) 100%)!important;
	}
}
.background-secondary-important {
	background-color: $brand-secondary!important;
	&.background-gradient {
		background: $brand-secondary!important;
		background: linear-gradient(145deg, $brand-secondary 0%, lighten($brand-secondary,15%) 100%)!important;
	}
}
.background-dark-important {
	background-color: $dark!important;
	&.background-gradient {
		background: $dark!important;
		background: linear-gradient(145deg, $dark 0%, lighten($dark,15%) 100%)!important;
	}
}
.background-gray-important {
	background-color: $gray!important;
	&.background-gradient {
		background: $gray!important;
		background: linear-gradient(145deg, $gray 0%, lighten($gray,15%) 100%)!important;
	}
}
.background-accent-important {
	background-color: $accent!important;
	&.background-gradient {
		background: $accent!important;
		background: linear-gradient(145deg, $accent 0%, lighten($accent,15%) 100%)!important;
	}
}
.background-light-important {
	background-color: $light!important;
	&.background-gradient {
		background: $light!important;
		background: linear-gradient(145deg, $light 0%, lighten($light,15%) 100%)!important;
	}
}

.background-error-important {
	background-color: $error!important;
	&.background-gradient {
		background: $error!important;
		background: linear-gradient(145deg, $error 0%, lighten($error,15%) 100%)!important;
	}
}

.background-transparent-important {
	background-color:transparent!important;
}

// Font Colors
.color-primary {
	color: $brand-primary;
}
.color-secondary {
	color:$brand-secondary;
}
.color-body {
	color: $body-font;
}
.color-light {
	color:$light;
}
.color-dark {
	color:$dark;
}
.color-gray {
	color:$gray;
}
.color-accent {
	color:$accent;
}
.color-white,.white {
	color:white;
}
.color-black {
	color:black;
}
.color-error {
	color:$error;
}

// important overrides
.color-primary-important {
	color: $brand-primary!important;
}
.color-secondary-important {
	color:$brand-secondary!important;
}
.color-body-important {
	color: $body-font!important;
}
.color-light-important {
	color:$light!important;
}
.color-dark-important {
	color:$dark!important;
}
.color-gray-important {
	color:$gray!important;
}
.color-accent-important {
	color:$accent!important;
}
.color-white-important {
	color:white!important;
}
.color-black-important {
	color:black!important;
}
.color-error-important {
	color:$error!important;
}

.brand-reference {
	position:relative;
	&:before {
		position: absolute;
		white-space: pre;
		display: inline;
		top: 50%;
		 left: 50%;
		transform: translate(-50%, -50%);
		font-size:14px;
		font-weight:600;
		border-radius:6px;
		padding:3px 4px;
		background:rgba(255,255,255,0.8);
		color:#222;
		text-align:center;
	}
}

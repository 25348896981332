/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.single-location-form {
  input[type="text"], textarea {
    border-radius:20px!important;
    border:1px solid white!important;
  }
  .gfield_required {
    display:none!important;
  }
  .location-form-message {
    textarea {
      max-height:125px!important;
    }
  }
  .gform_footer {
    margin-bottom:0!important;
  }
  .gform_button {
    margin-bottom:0!important;
  }
}
body {
  color: #fff;
  background: linear-gradient(180deg, #EE4405 0%,#F14FAB 27.87%,#F68020 46.07%,#EBF001 68.76%,#25C5CD 91.38%,#028288 100%,#21878C 100%);
  .container, .container-fluid {
    position: relative;
    z-index: 10;
  }
  .wrap {
    overflow-x: hidden;
  }
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

[class~="hurrytimer-campaign"] {
  padding: 0!important;
  height: 36px;
  margin: 0 auto;
  display: block;
}

.parallax-layers {
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  z-index: 0;
  .paraBg {
    background-size: cover!important;
    background-repeat: no-repeat!important;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
  }
}

.social-bubble {
	position: fixed;
	right: 1.25rem;
	bottom: 0;
	height: 110px;
	width: 110px;
	z-index: 100;
  @include media-breakpoint-down(md) {
    right: 8px;
  }
		a {
			color: #fff;
			font-size: 20px;
		}
	  a:nth-child(1) {
			position: absolute;
			top: 0;
			left: 10px;
      @include media-breakpoint-down(md) {
        left: 28px;
      }
			.icon-wrap {
				border:1px solid #fff;
				background: rgba(255,255,255,0.1);
				height: 52px;
				width: 52px;
				border-radius: 50%;
				transition: all 0.15s;
        @include media-breakpoint-down(md) {
          height: 45px;
          width: 45px;
        }
				&:hover {
					background: rgba(255,255,255,0.25);
				}
				i,svg {
					position: absolute;
					top: 16px;
					left: 17px;
          @include media-breakpoint-down(md) {
            top: 13px;
            left: 13px;
          }
				}
			}
		}
		a:nth-child(2) {
			position: absolute;
			bottom: 23px;
			right: 0;
			.icon-wrap {
				border:1px solid #fff;
				background: rgba(255,255,255,0.1);
				height: 45px;
				width: 45px;
				border-radius: 50%;
				transition: all 0.15s;
				&:hover {
					background: rgba(255,255,255,0.25);
				}
				i,svg {
					position: absolute;
					top: 13px;
					left: 14px;
				}
			}
		}
}

.loyalty-bubble {
	position: fixed;
	left: 1.25rem;
	bottom: 1.25rem;
	width: 152px;
	height: 152px;
	border:1px solid #fff;
	background: rgba(255,255,255,0.1);
	border-radius: 50%;
	text-align: center;
	padding: 38px 0;
	transition: all 0.15s;
  z-index: 100;
  line-height: 1.5;
	&:hover {
		background: rgba(255,255,255,0.25);
		cursor: pointer;
	}
  @include media-breakpoint-down(md) {
    width: 115px;
    height: 115px;
    font-size: 14px;
    padding: 20px 0;
    bottom: 8px;
    left: 8px;
  }
}

.sbi_load_btn {
  display: inline-block;
  padding: 0.75rem 1.5rem!important;
  border-radius: 30px;
  background: $brand-primary;
  color: $brand-secondary;
  transition-duration: 350ms;
  line-height: 1;
  font-size: auto;
  text-transform: uppercase;
  text-align: center;
  font-family: $subheading-font!important;
  font-weight: $heavy-weight!important;
  border: 4px solid $brand-primary;
  letter-spacing: 2px!important;
  &:hover {
    background: $brand-primary!important;
    color: $brand-secondary!important;
    opacity: 1!important;
  }
}

#accordionFaq {
  .h4 {
    color: $brand-secondary!important;
  }
  a {
    color: $brand-secondary;
  }
  .accordion-button, .accordion-button:not(.collapsed) {
    background: #f2f2f2!important;
    color: #000!important;
  }
}

.posts-navigation {
  .nav-links {
    a {
      color: $accent;
    }
  }
}

// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

// Accessibility Plugin Adjustments

#pojo-a11y-toolbar {
  height: 100%;
  top: 0!important;
}

.pojo-a11y-toolbar-toggle {
  bottom: 0!important;
}

.pojo-a11y-toolbar-overlay {
  position: absolute!important;
  bottom: 0!important;
  right: 0!important;
}

/** custom classes */

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 50vw;
  @include media-breakpoint-up(md) {
    min-height: 33vw;
  }
  @include media-breakpoint-up(lg) {
    min-height: 350px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.square {
  padding-top: 100%;
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.light-box-shadow {
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
}

.min-height-smaller {
  min-height: 33vh;
  @include media-breakpoint-up(md) {
    min-height: 300px;
  }
}

.min-height-md-down {
  @include media-breakpoint-down(md) {
    min-height: 400px;
  }
}

.pad-top-100 {
  padding-top: 100%!important;
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;
}

.large-icon * {
  font-size: 2.5em;
  @include media-breakpoint-up(md) {
    font-size: 3em;
  }
  @include media-breakpoint-up(md) {
    font-size: 4em;
  }
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;

  &.small-bio {
    padding: 30px;
    border: solid 5px #fff;
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}

.negative-top-header {
  margin-top: -47px;
  @include media-breakpoint-up(md) {
    margin-top: -115px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -130px;
  }
}

.clip-top {
  clip-path: polygon(0px 25px, 100% 0, 100% 100%, 0px 100%);
}

.clip-oblique {
  clip-path: polygon(0px 0px, 100% 3%, 100% 97%, 0px 100%);
  @include media-breakpoint-up(lg) {
    clip-path: polygon(0px 0px, 100% 8%, 100% 92%, 0px 100%);
  }
}

.border-bottom-trans-white {
  border-bottom: solid 1px rgba(255,255,255,.1);
}

.box-shadow {
  box-shadow: 0 0 30px rgba(0,0,0,0.1);
}

.box-shadow-small {
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
// Images

.img-wrap {
  img {
    max-height: 75px;
    margin: 10px;
  }
}

img {
  max-width: 100%;
}

.img-on-dark-bg {
  filter: brightness(0) invert(1);
}
// Util

.absolute-link {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.absolute-positioner {
  top: 0;
  left: 0;
}

.opacity-soft {
  opacity: 0.97;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-85 {
  opacity: 0.85;
}

.half-opacity {
  opacity: 0.5;
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}

.z-index-100 {
  z-index:100;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 90px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}

.card-radius{
  border-radius: 25px;
}

.card-img-top{
  border-radius: 25px, 25px, 0px, 0px;
}

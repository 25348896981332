.sidebar {

  .widget {
    text-align: center;
    background-color:rgba(255,255,255,0.35);
    border-radius:20px;
    border:1px solid white;
    margin-bottom: 2rem;
    color:white!important;
    &:last-child {
      border-bottom: 0px;
    }
    .primary-btn {
      @include media-breakpoint-down(lg) {
        font-size: 13px;
        line-height: 1.5em;
      }
    }
    .gform_wrapper input,
    .gform_wrapper textarea,
    .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
      background: #efefef;
      height: 30px;
      font-size: 13px !important;
      color: #888;
      border: solid 1px #ccc;
    }
    .gform_wrapper input[type="submit"] {
      background: $brand-primary !important;
      background: #00aeef;
      color: #fff;
      height: auto !important;
      border: 0px !important;
    }
    .boxed-header {
      padding: 15px;
      background: $brand-secondary;
      color: #fff !important;
    }
    li.gfield {
      margin-top: 5px;
      ::placeholder {
        color: #888;
      }
      input {
        border: solid 1px #ccc !important;
      }
    }
    li#field_2_2,
    li#field_2_1 {
      margin-top: 0px;
    }
    .menu {
      padding-left: 0px;
    }
    .textwidget {
      padding: 15px;
    }
  }
}
.contact .widget.gform_widget-2 {
  display: none !important;
}
.sidebar {
  margin-bottom: 0px;
  .menu {
    margin-bottom: 0;
  }
  .menu li {
    list-style: none;
    font-size: 14px;
    text-transform: uppercase;
    font-family: $paragraph-font;
    padding: 0.75rem 0;
    a {
      color:$brand-secondary;
      &:hover {
        color:$brand-primary;
      }
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  // Search bar
  .widget_search {
    background: transparent;
    box-shadow:none;
    .search-form {
      margin: 0;
      label {
        padding: 0;
      }
    }
  }
}

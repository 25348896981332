.template-homepage {
	.wrap {
		padding-bottom: 0!important;
	}
}

.gradient-top {
	&:before {
		content:'';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: linear-gradient(180deg,#ee4405 8%,#f14fab 50%,#f68020 75%,#ebf001 100%);
		z-index: -1;
	}
}

.gradient-bottom {
	&:before {
		content:'';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: linear-gradient(180deg, #d6f1b5 25%, #7bc6ca 100%);
		z-index: -1;
	}
}

.rpb-hero {
	.h1 {
		font-weight: 500;
		@include media-breakpoint-down(md) {
			font-size: 1.85rem;
		}
	}
	img.hero-logo {
		max-width: 775px;
		width: 100%;
		@include media-breakpoint-down(md) {
			width: 90%;
		}
	}
}

.cat-shop {
	border-radius: 50%;
	 .white-btn {
			display: block;
			position: relative;
			top: 45%;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			z-index: 100;
			padding: 12px 0;
		}
	 video {
		border-radius: 50%;
	}
	img {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

.cat-big {
	width: 405px;
	height: 405px;
	animation: flower-colorchange 10s infinite;
	-webkit-animation: flower-colorchange 10s infinite;
	overflow: hidden;
	img {
		border-radius: 50%;
		width: 100%;
	}
	@include media-breakpoint-down(xxl) {
		width: 365px;
		height: 365px;
	}
	@include media-breakpoint-down(xl) {
		width: 315px;
		height: 315px;
	}
	@include media-breakpoint-down(lg) {
		width: 240px;
		height: 240px;
	}
}

.cat-medium {
	height: 300px;
	width: 300px;
	overflow: hidden;
	@include media-breakpoint-down(xxl) {
		width: 265px;
		height: 265px;
	}
	@include media-breakpoint-down(lg) {
		width: 200px;
		height: 200px;
	}
}

.cat-small {
	width: 224px;
	height: 224px;
	overflow: hidden;
	@include media-breakpoint-down(xxl) {
		width: 200px;
		height: 200px;
	}
	@include media-breakpoint-down(lg) {
		width: 150px;
		height: 150px;
	}
}

.cat-flower {
	.primary-btn.white-btn {
		width: 175px;
	}
}

.cat-smoke {
	margin-top: -175px;
	margin-left: -80px;
	@include media-breakpoint-down(md) {
		margin: 0;
	}
}

.cat-edibles {
	margin-top: -125px;
	@include media-breakpoint-down(md) {
		margin: 1.5rem 0;
		float: right;
	}
	img {
		animation: rotate45 10s infinite;
		-webkit-animation: rotate45 10s infinite;
	}
	.primary-btn.white-btn {
		width: 175px;
	}
}

.cat-concentrates {
	animation: concentrates-colorchange 10s infinite;
	-webkit-animation: concentrates-colorchange 10s infinite;
	@include media-breakpoint-down(lg) {
		width: 265px;
		height: 265px;
	}
	@include media-breakpoint-down(md) {
		margin: 1.5rem 0;
	}
	.primary-btn.white-btn {
		width: 264px;
		@include media-breakpoint-down(lg) {
			width: 242px;
		}
	}
}

.cat-vapes {
	margin-top: -255px;
	@include media-breakpoint-down(md) {
		margin: 0;
		float: right;
	}
	img {
		-webkit-animation: rotate360 60s infinite;
		animation: rotate360 60s infinite;
	}
	.primary-btn.white-btn {
		width: 162px;
	}
}

.cat-pets {
	animation: pets-colorchange 10s infinite;
	-webkit-animation: pets-colorchange 10s infinite;
	@include media-breakpoint-down(md) {
		float: right;
	}
	.primary-btn.white-btn {
		width: 142px;
	}
}

.cat-cbd {
	margin-top: -255px;
	@include media-breakpoint-down(lg) {
		margin-left: -25px;
	}
	@include media-breakpoint-down(md) {
		margin: 0;
	}
	animation: cbd-colorchange 12s infinite;
	-webkit-animation: cbd-colorchange 12s infinite;
	.primary-btn.white-btn {
		width: 135px;
	}
}

.cat-treats {
	background-color: #e5f753;
	margin-top: -10px;
	@include media-breakpoint-down(md) {
		margin: 1.5rem 0 0;
		float: right;
	}
	img {
		position: absolute;
		top: 0;
		left: 0;
	}
	.home-cat-small-1 {
		animation: treats-1-3 10s infinite;
	}
	.home-cat-small-2 {
		animation: treats-2-4 10s infinite;
	}
	.home-cat-small-3 {
		animation: treats-1-3 10s infinite;
	}
	.home-cat-small-4 {
		animation: treats-2-4 10s infinite;
	}
}

.cat-gummies {
	@include media-breakpoint-down(md) {
		margin: -25px 0 0;
	}
	img {
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 50%;
	}
	.home-cat-small-1 {
		animation: rotate5 10s infinite;
	}
	.home-cat-small-2 {
		animation: rotate-5 10s infinite;
	}
	.home-cat-small-3 {
		animation: rotate5 10s infinite;
	}
	.home-cat-small-4 {
		animation: rotate-5 10s infinite;
	}
}

.home-cta {
	.home-cta-title {
		font-size: 94px;
		@include media-breakpoint-down(lg) {
			font-size: 76px;
		}
		@include media-breakpoint-down(md) {
			font-size: 48px;
		}
	}
}

.daily-deals-title-section {
	z-index: -1;
	.daily-deals-title  {
		font-size: 100px;
		@include media-breakpoint-down(lg) {
			font-size: 76px;
		}
		@include media-breakpoint-down(md) {
			font-size: 48px;
		}
	}
	.wave-break-top {
	  height:200px;
	  position:absolute;
		left: 0;
		top: 0;
		width: 100%;
		&:before,
		&:after{
		  content:"";
		  position:absolute;
		  top:0;
		  left:0;
		  right:0;
		  height:100%;
		  background: transprent;
		}
		&:after {
		  -webkit-mask:
		    radial-gradient(100% 80% at top   ,white 79.5%,transparent 80%) left,
		    radial-gradient(100% 80% at bottom,transparent 79.5%,white 80%) right;
		  mask:
		    radial-gradient(100% 80% at top   ,white 79.5%,transparent 80%) left,
		    radial-gradient(100% 80% at bottom,transparent 79.5%,white 80%) right;
		  -webkit-mask-size:50.1% 100%;
		  -webkit-mask-repeat:no-repeat;
		  mask-size:50.22% 100%;
		  mask-repeat:no-repeat;
		  background: #ebf001;
		}
	}
	.wave-break-bottom {
		height:110px;
		position:absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		transform: rotate(180deg);
		&:before,
		&:after{
			content:"";
			position:absolute;
			top:0;
			left:0;
			right:0;
			height:100%;
			background: transprent;
		}
		&:after {
			-webkit-mask:
				radial-gradient(100% 80% at top   ,white 79.5%,transparent 80%) left,
				radial-gradient(100% 80% at bottom,transparent 79.5%,white 80%) right;
			mask:
				radial-gradient(100% 80% at top   ,white 79.5%,transparent 80%) left,
				radial-gradient(100% 80% at bottom,transparent 79.5%,white 80%) right;
			-webkit-mask-size:50.1% 100%;
			-webkit-mask-repeat:no-repeat;
			mask-size:50.22% 100%;
			mask-repeat:no-repeat;
			background: #cdedb7;
		}
	}
}

.daily-deals {
	margin-top: -12rem;
}

#joyWave {
	display: block;
	width: 100%;
	@include media-breakpoint-down(lg) {
		font-size: 29px;
	}
	svg {
	  width: 100%;
	  height: 100px;
	}
	#wave {
		path {
			fill: transparent;
		}
		text {
			fill:#fff;
		}
	}
}

.rpb-blog-feed {
	.blog-feed-title {
		color: $accent;
		font-size: 94px;
		@include media-breakpoint-down(lg) {
			font-size: 76px;
		}
		@include media-breakpoint-down(md) {
			font-size: 48px;
		}
	}
}
